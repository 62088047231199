$(document).ready(function () {
  /* =====  MOBILE MENU TOGGLE  ====== */
  $(".offCanvasToggler").click(function (event) {
    event.preventDefault();
    $(".off-canvas-nav").toggleClass("active");
    $(".fullscreen-overlay").toggleClass("active");
    $("html, body").toggleClass("no-scrolling");
  });

  $(".home-slider-slides").slick({
    centerMode: true,
    centerPadding: "150px",
    slidesToShow: 2,
    arrows: true,
    prevArrow: $(".home-slider .slider-controls .prev-slide"),
    nextArrow: $(".home-slider .slider-controls .next-slide"),
    autoplay: true,
    autoplaySpeed: 5000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          centerPadding: "10px",
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  $(".grid").isotope({
    itemSelector: ".grid-item",
    percentPosition: true,
    masonry: {
      columnWidth: ".grid-sizer",
      gutter: 30,
    },
  });

  $(".datepicker").pickadate({
    monthsFull: [
      "Januari",
      "Februari",
      "Maart",
      "April",
      "Mei",
      "Juni",
      "Juli",
      "Augustus",
      "September",
      "Oktober",
      "November",
      "December",
    ],
    monthsShort: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Okt",
      "Nov",
      "Dec",
    ],
    weekdaysFull: [
      "Zondag",
      "Maandag",
      "Dinsdag",
      "Woensdag",
      "Donderdag",
      "Vrijdag",
      "Zaterdag",
    ],
    weekdaysShort: ["Zon", "Maa", "Din", "Woe", "Don", "Vri", "Zat"],

    // Buttons
    today: "Vandaag",
    clear: "Wis",
    close: "Sluit",

    // Accessibility labels
    labelMonthNext: "Volgende maand",
    labelMonthPrev: "Vorige maand",
    labelMonthSelect: "Kies een maand",
    labelYearSelect: "Kies een jaar",

    // Formats
    format: "d/m/yyyy",

    // First day of the week
    firstDay: 1,

    // Close on a user action
    closeOnSelect: true,
    closeOnClear: true,
  });

  $(".faq-vraag").click(function (event) {
    event.preventDefault();
    const faq = $(this).data("faq");
    $(".faq-vraag").removeClass("active");
    $(`.faq-vraag[data-faq=${faq}]`).addClass("active");

    $(".faq-antwoord").slideUp();
    $(`.faq-antwoord[data-faq=${faq}]`).slideToggle();
  });

  $(".filters a").click(function (event) {
    event.preventDefault();
    const targetFilter = $(this).data("filter");

    $(".document-grid").isotope({ filter: `${targetFilter}` });
  });

  $(".internal-sidebar .folder-header a").click(function (event) {
    event.preventDefault();
    const folder = $(this).data("folder");
    $(this).parent(".folder-header").toggleClass("active");
    $(
      `.internal-sidebar .folder-content[data-folder="${folder}"]`
    ).slideToggle();
  });

  $(".internal-sidebar .folder-header .folder-name").click(function (event) {
    event.preventDefault();
    const targetFilter = $(this).data("filter");
    $(".internal-sidebar .folder-header .folder-name").removeClass(
      "active-filter"
    );
    $(this).addClass("active-filter");

    $(".document-grid").isotope({ filter: `${targetFilter}` });
  });

  // zoekfunctie
  let qsRegex;

  $(".grid").isotope({
    filter: function () {
      return qsRegex ? $(this).text().match(qsRegex) : true;
    },
  });

  let quicksearch = $(".quicksearch").keyup(
    debounce(function () {
      qsRegex = new RegExp(quicksearch.val(), "gi");
      $(".grid").isotope();
      console.log("test");
    }, 100)
  );

  function debounce(fn, threshold) {
    let timeout;
    threshold = threshold || 100;
    return function debounced() {
      clearTimeout(timeout);
      let args = arguments;
      let _this = this;
      function delayed() {
        fn.apply(_this, args);
      }
      timeout = setTimeout(delayed, threshold);
    };
  }

  /*======  CONNECT 14 - 25  ======*/

  /**
   * CHECK IF THE CURRENT PAGE CONTAINS THE
   * CONNECT 14 - 25 FORM.
   *
   * IF A PSYCHOSE RELATED RADIO BUTTON IS CHANGED,
   * CREATE AND UPDATE A VAR connectScore WITH THE
   * ACCUMULATION OF EACH INPUT CHECKED VALUE.
   *
   * LASTLY, UPDATE THE TOTAL INPUT WITH THE
   * TOTAL OF ALL VALUES.
   */
  if ($("#FormBuilder_connect_14_25").length) {
    $(".psychoseRadio").change(function () {
      let connectScore = 0;

      $(".psychoseRadio input:checked").each(function (index, el) {
        connectScore = connectScore + parseInt($(this).val(), 10);
      });

      $("#Inputfield_totaal_score").val(connectScore);
    });
  }

  $(".flip-card").hover(
    function () {
      $(this).addClass("toggle-flipped");
    },
    function () {
      /* Stuff to do when the mouse leaves the element */
    }
  );

  // ==========[ STANDARD FORM HANDLER ]==========
  $("#submit_form").click(function (event) {
    $(".loader").removeClass("d-none");
    let form = $("#submitted_form");
    let form_data = new FormData(form[0]);
    form_data.append("js_url", "js_set");
    $.ajax({
      url: "/ajax/",
      data: form_data,
      enctype: "multipart/form-data",
      dataType: "json",
      type: "post",
      processData: false,
      contentType: false,
      success: function (data) {
        $(".loader").addClass("d-none");
        if (data.success) {
          if (data.redirect) {
            window.location.href = data.redirect;
          } else {
            // ==========[ SET SUCCESS WRAPPER ]==========
            let success_wrapper = $(".feedback-wrapper");
            success_wrapper.removeClass("d-none");
            success_wrapper.empty();

            // ==========[ CREATE SUCCESS AND APPEND ]==========
            let success_message =
              '<div class="alert alert-success" role="alert">';
            success_message += data.success_message;
            success_message +=
              '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
            success_message += "</div>";
            success_wrapper.append(success_message);

            // ==========[ HIDE FORM ]==========
            form.addClass("d-none");
          }
        } else {
          // ==========[ ADD ERROR MESSAGES ]==========
          let errors_wrapper = $(".feedback-wrapper");
          errors_wrapper.removeClass("d-none");
          errors_wrapper.empty();
          $(data.errors_messages).each(function (i, val) {
            let error = '<div class="alert alert-danger" role="alert">';
            error += val;
            error +=
              '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
            error += "</div>";
            errors_wrapper.append(error);
          });
        }
      },
    });
  });
  let cookiebanner = $("#cookie-modal");

  console.log(localStorage.getItem('cookie'));
  if(!localStorage.getItem('cookie')) {
    cookiebanner.removeClass("d-none");
  }
  $("#approve_all").click(function (event) {
    cookiebanner.addClass("d-none");
    localStorage.setItem('cookie','yes');
    console.log("ja");
  });
  $("#discard_all").click(function (event) {
    cookiebanner.addClass("d-none");
    localStorage.setItem('cookie','no');

    console.log("nee");
  });
 

  $("#password-form").submit(function (event) {
    event.preventDefault();
    let form = $("#password-form");
    let form_data = new FormData(form[0]);
    form_data.append("js_url", "js_set");
    $.ajax({
      url: "/ajax/",
      data: form_data,
      enctype: "multipart/form-data",
      dataType: "json",
      type: "post",
      processData: false,
      contentType: false,
      success: function (data) {
        if (data.success) {
          // ==========[ SET SUCCESS WRAPPER ]==========
          let wrapper = $(".js-has-password");
          wrapper.addClass("d-none");
        } else {
          // ==========[ ADD ERROR MESSAGES ]==========
          let errors_wrapper = $(".feedback-wrapper");
          errors_wrapper.removeClass("d-none");
          errors_wrapper.empty();
          $(data.errors_messages).each(function (i, val) {
            let error = '<div class="alert alert-danger" role="alert">';
            error += val;
            error +=
              '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
            error += "</div>";
            errors_wrapper.append(error);
          });
        }
      },
    });
  });
});
